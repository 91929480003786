const lang = {
    landing: {
        ko: {
            title: "제주 4・3 메타버스",
            subTitle: "어둠의 역사를 빛의 역사로!"
        },
        en: {
            title: "Jeju 4・3 Metaverse",
            subTitle: "Walking the Jeju 4・3 Trails in Metaverse!"
        },
        cn: {
            title: "济州4・3元宇宙",
            subTitle: "走遍元宇宙济州43条小道！"
        },
        jp: {
            title: "済州4・3メタバース",
            subTitle: "メタバースで済州43トレイルを歩く!"
        },
    },
    introEnter: {
        ko: "입장하기",
        en: "Enterance",
        cn: "入口",
        jp: "入場"
    },
    linkModal: {
        ko: {
            title: "연관 사이트",
        },
        en: {
            title: "Related Organization",
        },
        cn: {
            title: "有关机构",
        },
        jp: {
            title: "関連機関"
        },
    },
    mainMenu: {
        ko: {
            menu: "메뉴",
        },
        en: {
            menu: "Menu",
        },
        cn: {
            menu: "菜单",
        },
        jp: {
            menu: "メニュー"
        },
    },
    mainModal: {
        ko: {
            1: {
                title: "백조일손지지/섯알오름/만벵듸",
                vrButtons: [
                    {
                        name: "백조일손지지",
                        link: "http://40.89.198.8/baekjoilsonjiji_collective_cemetery/"
                    },
                    {
                        name: "섯알오름",
                        link: "http://40.89.198.8/seodal_oreum/"
                    },
                    {
                        name: "만벵듸",
                        link: "http://40.89.198.8/manbengdui_cemetery/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "모슬포 섯알오름에서 집단학살과, 같은 날 백조일손지지의 다른 구덩이에서 학살사건이 일어났습니다. 백조일손지지와 만벵듸는 희생자의 집단묘지입니다.",
                address: "대정읍 상모리 586-1 / 대정읍 상모리 1597-3번지 / 한림읍 갯거리오름길 114"
            },
            2: {
                title: "곤을동 잃어버린 마을",
                vrButtons: [
                    {
                        name: "곤을동 잃어버린 마을",
                        link: "http://40.89.198.8/lost_village_goneul-dong/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "잃어버린 마을 곤을동은 제주시 중심가와 가까운 별도봉 동편 자락에 위치한 4·3 유적지입니다. 4·3 사건 당시 전소된 가옥중에서 복구되지 못한 마을을 ‘잃어버린 마을’이라 부릅니다.",
                address: "제주시 화북1동 4440번지"
            },
            3: {
                title: "주정공장 옛터",
                vrButtons: [
                    {
                        name: "주정공장 옛터",
                        link: "http://40.89.198.8/former_distillery_site/"
                    },
                    {
                        name: "주정공장수용소 4・3 역사관",
                        link: "https://premium.360vrmuseum.com/?m=ByMaSeN1Rfi&play=0&qs=0&hr=1&maprotation=0&isFloorDistinction=1&logo=1&search=0"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "제주주정공장은 고구마를 주원료로 생산하는 대규모 공장으로, 4·3 사건 당시 수용소로 활용 되었습니다. 지금은 당시의 건물들이 모두 헐려 그 흔적은 찾아볼 수 없습니다.",
                address: "제주시 만덕로 3길 26"
            },
            4: {
                title: "북촌 너븐숭이",
                vrButtons: [
                    {
                        name: "북촌 너븐숭이",
                        link: "http://40.89.198.8/neobeunsungi/"
                    },
                    {
                        name: "너븐숭이 4・3 기념관",
                        link: "https://premium.360vrmuseum.com/?m=xkSFWj7dPeV&play=0&qs=0&hr=1&search=0&logo=1"
                    },
                ],
                spaceButtons: ["METAVERSE"],
                description: "북촌 너븐숭이는 북촌리 조천읍의 4·3 사건의 가장 피해가 큰 학살 장소 입니다. 학살된 북촌리 주민들을 위령하기 위해 너븐숭이 빌레를 조성 하여 위령비, 기념관 등이 건립되어 있습니다.",
                address: "조천읍 북촌리 1179-2번지"
            },
            5: {
                title: "낙선동 4・3성",
                vrButtons: [
                    {
                        name: "낙선동 4・3성",
                        link: "http://40.89.198.8/nackseon-dong_43fortress/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "아픈 세월을 이기고 살아남은 주민들이 낙선동에 성을 쌓고 집단 거주 하였습니다. 4·3성은 무장대의 침입을 막는다는 명분으로 마을에 쌓은 성 중에서 원형을 가장 잘 보존하고 있는 유적지입니다.",
                address: "조천읍 선흘서5길 7"
            },
            6: {
                title: "다랑쉬굴",
                vrButtons: [
                    {
                        name: "다랑쉬굴",
                        link: "http://40.89.198.8/darangshi_cave/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: " 다랑쉬굴은 하도리와 종달리 주민들이 피신해 살다가 발각되어 집단 학살된 장소입니다. 지금도 그들이 사용했던 솥, 항아리, 물허벅 등 생활도구가 굴 속에 그대로 남아있습니다.",
                address: "구좌읍 세화리 2608-6번지"
            },
            7: {
                title: "4・3 메모리얼 파크",
                subTitle: "4・3 Memorial Park",
                vrButtons: [],
                spaceButtons: ["METAVERSE"],
                description: " 4·3 메모리얼 파크는 흩어져 있는 4·3 유적지를 한 공간에서 기억해 볼 수 있는 공간입니다. 4·3 유적지의 본 모습과 함께 그 안에 숨겨진 또 다른 의미를 찾아보세요."
            }
        },
        en: {
            1: {
                title: "Baekjoilsonjiji/Seodal Oreum/Manbengdui",
                vrButtons: [
                    {
                        name: "Baekjoilsonjiji Collective Cemetery",
                        link: "http://40.89.198.8/baekjoilsonjiji_collective_cemetery/"
                    },
                    {
                        name: "Seodal Oreum",
                        link: "http://40.89.198.8/seodal_oreum/"
                    },
                    {
                        name: "Manbengdui Cemetery",
                        link: "http://40.89.198.8/manbengdui_cemetery/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "A number of people who were detained during preventive detention maneuvers and killed at Seodal Oreum are buried here while others are buried in Baekjoilsonjiji. It was not possible to identify the bodies so the families assembled a communal graveyard for all the victims."
            },
            2: {
                title: "The Lost Village of Goneul-dong",
                vrButtons: [
                    {
                        name: "The Lost Village of Goneul-dong",
                        link: "http://40.89.198.8/lost_village_goneul-dong/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Lost Village of Goneul-dong is located at the central Jeju. Among the villages were destroyed during the Jeju 4·3. But the village was never restored."
            },
            3: {
                title: "Former Distillery Site",
                vrButtons: [
                    {
                        name: "Former Distillery Site",
                        link: "http://40.89.198.8/former_distillery_site/"
                    },
                    {
                        name: "4・3 History Museum",
                        link: "https://my.matterport.com/show/?m=ByMaSeN1Rfi"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Oriental Development Company Jeju Distillery was an important industrial facility before and after liberation. People who were arrested as part of pacification activities were detained in this warehouse during the Jeju 4·3."
            },
            4: {
                title: "Neobeunsungi",
                vrButtons: [
                    {
                        name: "Neobeunsungi",
                        link: "http://40.89.198.8/neobeunsungi/"
                    },
                    {
                        name: "Neobeunsungi 4・3 Memorial Hall",
                        link: "https://premium.360vrmuseum.com/?m=xkSFWj7dPeV&play=0&qs=0&hr=1&search=0&logo=1"
                    },
                ],
                spaceButtons: ["METAVERSE"],
                description: "The massacre committed at Bukchon village, it stands out for its brutality even among the horrors of the Jeju 4·3. In Neobeunsungi, a memorial hall and a memorial monument were built near the filed."
            },
            5: {
                title: "Nakseon-dong 4・3 Fortress",
                vrButtons: [
                    {
                        name: "Nakseon-dong 4・3 Fortress",
                        link: "http://40.89.198.8/nackseon-dong_43fortress/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Nakseon-dong 4·3 Fortress is relatively well preserved. The Fortress-like enclosure was purportedly built to block and prevent attacks from the armed resistance."
            },
            6: {
                title: "Darangshi Cave",
                vrButtons: [
                    {
                        name: "Darangshi Cave",
                        link: "http://40.89.198.8/darangshi_cave/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "Eleven villagers of Hado-ri and Jongdal-ri hid from the massacre in Darangshi cave until they were discovered by state forces. Darangshi cave as it was discovered in 1992 with some of the items used by the victims while they hid in the cave."
            },
            7: {
                title: "4・3 Memorial Park",
                vrButtons: [],
                spaceButtons: ["METAVERSE"],
                description: "The 4·3 Memorial Park contained to provide a valuable opportunity to visit the metaverse space. Find about how the significance of sculptures impacted in each space by the historical telling."
            }
        },
        cn: {
            1: {
                title: "百祖一孙墓/西卵岳/Manbengdui村庄",
                vrButtons: [
                    {
                        name: "百祖一孙墓",
                        link: "http://40.89.198.8/baekjoilsonjiji_collective_cemetery/"
                    },
                    {
                        name: "西卵岳",
                        link: "http://40.89.198.8/seodal_oreum/"
                    },
                    {
                        name: "Manbengdui",
                        link: "http://40.89.198.8/manbengdui_cemetery/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "A number of people who were detained during preventive detention maneuvers and killed at Seodal Oreum are buried here while others are buried in Baekjoilsonjiji. It was not possible to identify the bodies so the families assembled a communal graveyard for all the victims."
            },
            2: {
                title: "坤乙洞",
                vrButtons: [
                    {
                        name: "坤乙洞",
                        link: "http://40.89.198.8/lost_village_goneul-dong/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Lost Village of Goneul-dong is located at the central Jeju. Among the villages were destroyed during the Jeju 4·3. But the village was never restored."
            },
            3: {
                title: "Former Distillery Site",
                vrButtons: [
                    {
                        name: "Former Distillery Site",
                        link: "http://40.89.198.8/former_distillery_site/"
                    },
                    {
                        name: "4・3 History Museum",
                        link: "https://my.matterport.com/show/?m=ByMaSeN1Rfi"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Oriental Development Company Jeju Distillery was an important industrial facility before and after liberation. People who were arrested as part of pacification activities were detained in this warehouse during the Jeju 4·3."
            },
            4: {
                title: "北村宽石",
                vrButtons: [
                    {
                        name: "北村宽石",
                        link: "http://40.89.198.8/neobeunsungi/"
                    },
                    {
                        name: "宽石4.3纪念馆",
                        link: "https://premium.360vrmuseum.com/?m=xkSFWj7dPeV&play=0&qs=0&hr=1&search=0&logo=1"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The massacre committed at Bukchon village, it stands out for its brutality even among the horrors of the Jeju 4·3. In Neobeunsungi, a memorial hall and a memorial monument were built near the filed."
            },
            5: {
                title: "Nakseon-dong 4・3 Fortress",
                vrButtons: [
                    {
                        name: "Nakseon-dong 4・3 Fortress",
                        link: "http://40.89.198.8/nackseon-dong_43fortress/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Nakseon-dong 4·3 Fortress is relatively well preserved. The Fortress-like enclosure was purportedly built to block and prevent attacks from the armed resistance."
            },
            6: {
                title: "月郎峰洞穴",
                vrButtons: [
                    {
                        name: "月郎峰洞穴",
                        link: "http://40.89.198.8/darangshi_cave/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "Eleven villagers of Hado-ri and Jongdal-ri hid from the massacre in Darangshi cave until they were discovered by state forces. Darangshi cave as it was discovered in 1992 with some of the items used by the victims while they hid in the cave."
            },
            7: {
                title: "4・3 纪念公园",
                vrButtons: [],
                spaceButtons: ["METAVERSE"],
                description: "The 4·3 Memorial Park contained to provide a valuable opportunity to visit the metaverse space. Find about how the significance of sculptures impacted in each space by the historical telling."
            }
        },
        jp: {
            1: {
                title: "伯祖一孫墓域/ソダルオルム/マンベンジ村",
                vrButtons: [
                    {
                        name: "伯祖一孫墓域",
                        link: "http://40.89.198.8/baekjoilsonjiji_collective_cemetery/"
                    },
                    {
                        name: "ソダルオルム",
                        link: "http://40.89.198.8/seodal_oreum/"
                    },
                    {
                        name: "マンベンジ",
                        link: "http://40.89.198.8/manbengdui_cemetery/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "A number of people who were detained during preventive detention maneuvers and killed at Seodal Oreum are buried here while others are buried in Baekjoilsonjiji. It was not possible to identify the bodies so the families assembled a communal graveyard for all the victims."
            },
            2: {
                title: "コンウルドン",
                vrButtons: [
                    {
                        name: "コンウルドン",
                        link: "http://40.89.198.8/lost_village_goneul-dong/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Lost Village of Goneul-dong is located at the central Jeju. Among the villages were destroyed during the Jeju 4·3. But the village was never restored."
            },
            3: {
                title: "Former Distillery Site",
                vrButtons: [
                    {
                        name: "Former Distillery Site",
                        link: "http://40.89.198.8/former_distillery_site/"
                    },
                    {
                        name: "4・3 History Museum",
                        link: "https://my.matterport.com/show/?m=ByMaSeN1Rfi"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Oriental Development Company Jeju Distillery was an important industrial facility before and after liberation. People who were arrested as part of pacification activities were detained in this warehouse during the Jeju 4·3."
            },
            4: {
                title: "北村ノブンスンイ",
                vrButtons: [
                    {
                        name: "北村ノブンスンイ",
                        link: "http://40.89.198.8/neobeunsungi/"
                    },
                    {
                        name: "ノブンスンイ4.3記念館",
                        link: "https://premium.360vrmuseum.com/?m=xkSFWj7dPeV&play=0&qs=0&hr=1&search=0&logo=1"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The massacre committed at Bukchon village, it stands out for its brutality even among the horrors of the Jeju 4·3. In Neobeunsungi, a memorial hall and a memorial monument were built near the filed."
            },
            5: {
                title: "Nakseon-dong 4・3 Fortress",
                vrButtons: [
                    {
                        name: "Nakseon-dong 4・3 Fortress",
                        link: "http://40.89.198.8/nackseon-dong_43fortress/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "The Nakseon-dong 4·3 Fortress is relatively well preserved. The Fortress-like enclosure was purportedly built to block and prevent attacks from the armed resistance."
            },
            6: {
                title: "タランシ 洞窟",
                vrButtons: [
                    {
                        name: "タランシ 洞窟",
                        link: "http://40.89.198.8/darangshi_cave/"
                    }
                ],
                spaceButtons: ["METAVERSE"],
                description: "Eleven villagers of Hado-ri and Jongdal-ri hid from the massacre in Darangshi cave until they were discovered by state forces. Darangshi cave as it was discovered in 1992 with some of the items used by the victims while they hid in the cave."
            },
            7: {
                title: "4・3 記念公園",
                vrButtons: [],
                spaceButtons: ["METAVERSE"],
                description: "The 4·3 Memorial Park contained to provide a valuable opportunity to visit the metaverse space. Find about how the significance of sculptures impacted in each space by the historical telling."
            }
        }
    }
}

export default lang;